import * as React from 'react';
import { Helmet } from 'react-helmet';
import FxLayout from '../components/shared/layout/layout';

interface I404PageState {
  message: string;
}

interface I404PageProps {
  pageContext: any;
}

export default class PageNotFoundPage extends React.Component<
  I404PageProps,
  I404PageState
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <FxLayout>
        {<Helmet />}
        <div className="fx-l-404">
        <div className="fx-title--subheading">404!</div>
        <hr className="hr--primary" />
          <div className="fx-title--blurb">
            Oops, something went wrong! The page your looking for doesn't exist.
          </div>
        </div>
      </FxLayout>
    );
  }
}
